<template>
  <div class="load">
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        style="margin: auto; display: block"
        width="300px"
        height="300px"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <g>
          <circle cx="67.0918" cy="50" r="4" fill="#00bb8e">
            <animate
              attributeName="cx"
              repeatCount="indefinite"
              dur="1s"
              values="95;35"
              keyTimes="0;1"
              begin="-0.67s"
            ></animate>
            <animate
              attributeName="fill-opacity"
              repeatCount="indefinite"
              dur="1s"
              values="0;1;1"
              keyTimes="0;0.2;1"
              begin="-0.67s"
            ></animate>
          </circle>
          <circle cx="87.4918" cy="50" r="4" fill="#00bb8e">
            <animate
              attributeName="cx"
              repeatCount="indefinite"
              dur="1s"
              values="95;35"
              keyTimes="0;1"
              begin="-0.33s"
            ></animate>
            <animate
              attributeName="fill-opacity"
              repeatCount="indefinite"
              dur="1s"
              values="0;1;1"
              keyTimes="0;0.2;1"
              begin="-0.33s"
            ></animate>
          </circle>
          <circle cx="47.2918" cy="50" r="4" fill="#00bb8e">
            <animate
              attributeName="cx"
              repeatCount="indefinite"
              dur="1s"
              values="95;35"
              keyTimes="0;1"
              begin="0s"
            ></animate>
            <animate
              attributeName="fill-opacity"
              repeatCount="indefinite"
              dur="1s"
              values="0;1;1"
              keyTimes="0;0.2;1"
              begin="0s"
            ></animate>
          </circle>
        </g>
        <g transform="translate(-15 0)">
          <path
            d="M50 50L20 50A30 30 0 0 0 80 50Z"
            fill="#00bb8e"
            transform="rotate(90 50 50)"
          ></path>
          <path
            d="M50 50L20 50A30 30 0 0 0 80 50Z"
            fill="#00bb8e"
            transform="rotate(18.4377 50 50)"
          >
            <animateTransform
              attributeName="transform"
              type="rotate"
              repeatCount="indefinite"
              dur="1s"
              values="0 50 50;45 50 50;0 50 50"
              keyTimes="0;0.5;1"
            ></animateTransform>
          </path>
          <path
            d="M50 50L20 50A30 30 0 0 1 80 50Z"
            fill="#00bb8e"
            transform="rotate(-18.4377 50 50)"
          >
            <animateTransform
              attributeName="transform"
              type="rotate"
              repeatCount="indefinite"
              dur="1s"
              values="0 50 50;-45 50 50;0 50 50"
              keyTimes="0;0.5;1"
            ></animateTransform>
          </path>
        </g>
      </svg>
      <div class="load-num">
        {{ Math.floor(this.$store.state.loadNum * 100) + "%" }}
      </div>
    </div>
  </div>
</template>
    
<script>
export default {
  name: "",
  data() {
    return {};
  },
  methods: {},
};
</script>
    
<style scoped>
.load {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  height: 100vh;
}
.load-num {
  color: #05ceff;
  font-size: 40px;
  text-align: center;
}
</style>